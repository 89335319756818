<template>
<div class="keypad">
    <button v-for="button in keypadButtons" :key="button.value" @click.prevent="$emit('changePin', button.value)" :class="{backspaceWhite: !!isFilled}">
      {{ button.label }}
    </button>
</div>
</template>
<script>

export default {
  name: 'CellPhoneKeypad',
  emits: ['changePin'],
  props: {
    isLogin: {
      type: Boolean,
      default: false
    },
    isFilled: {
      type: Number,
      required: true
    }
  },
  setup (props) {
    const keypadButtons = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
      { label: '6', value: '6' },
      { label: '7', value: '7' },
      { label: '8', value: '8' },
      { label: '9', value: '9' },
      { label: props.isLogin ? 'Войти без авторизации' : '', value: '' },
      { label: '0', value: '0' },
      { label: '', value: -1 }
    ]

    return {
      keypadButtons
    }
  }
}
</script>
<style scoped lang="scss">
.keypad {
  margin-top: 30px;
  padding: 0 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 40px;

  button {
    all: unset;
    font-size: 36px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.02em;
    display: flex;
    justify-content: center;
    align-items: center;

    &:last-child {
      margin-right: 10px;
      font-weight: 100;
      font-size: 33px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("@/assets/uploads/icon/common/backspace-icon.svg");
      opacity: 0.4;
    }
    &:nth-child(10) {
      margin-left: 20px;
      font-size: 13px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0.02em;
      text-align: left;
    }
  }
}
.backspaceWhite {
  &:last-child {
     opacity: 1 !important;
    }
}
</style>
