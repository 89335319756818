<template>
  <header class="header" :style="{ paddingTop: headerPadding }">
    <div class="container header__container">
      <div class="header__col header__col--left">
        <div v-if="!user" class="header__login header__login--mobile">
          <div class="header__login-link" @click="closeMenu">
            <router-link to="/auth/phone" style="display: inline-block">
              вход/регистрация
            </router-link>
          </div>
        </div>
        <div
          class="header__user-select"
          v-if="user"
          @click="openUserList"
          v-click-away="hideUserList"
        >
          <div v-if="userPhoto" class="header__user-icon-profile">
            <img :src="userPhoto" alt="фото пользователя" />
          </div>
          <div v-else class="header__user-icon" :style="userColor(user.icon)">
            <img
              :src="require(`@/assets/uploads/icon/profile/${user.icon}.svg`)"
              alt="фото пользователя"
            />
          </div>
          <div class="header__user-name">
            <p>{{ user.first_name }} {{ user.last_name }}</p>
            <span v-if="user.card"
              ><span
                v-if="user.id === userPrimaryId && user.family_cards.length > 1"
                >Основной. </span
              >№ {{ user.card }}
            </span>
          </div>
          <img
            v-if="user.family_cards && user.family_cards.length > 1"
            src="@/assets/uploads/icon/common/arrow-back.svg"
            class="header__user-arrow"
            :class="{ opened: isOpenUserList === true }"
            alt="стрелка"
          />
          <transition name="slide">
            <div
              class="header__user-modal"
              v-if="isOpenUserList && user.family_cards.length > 1"
            >
              <ul class="header__user-list">
                <li
                  v-for="card in user.family_cards"
                  :key="card.id"
                  @click="changeCurrentUser(card)"
                  :class="{ primary: card.id === user.id }"
                >
                  <div v-if="userPhoto" class="header__user-icon-profile">
                    <img :src="userPhoto" alt="фото пользователя" />
                  </div>
                  <div
                    v-else
                    class="header__user-icon"
                    :style="userColor(user.icon)"
                  >
                    <img
                      :src="
                        require(`@/assets/uploads/icon/profile/${user.icon}.svg`)
                      "
                      alt="фото пользователя"
                    />
                  </div>
                  <div class="header__user-name">
                    <p>{{ card.first_name }} {{ card.last_name }}</p>
                    <span v-if="card.card"
                      ><span v-if="card.id === userPrimaryId"
                        >Основной профиль.</span
                      >
                      № {{ card.card }}
                    </span>
                  </div>
                </li>
              </ul>
              <a class="menu__user-logout" @click.prevent="logOut">
                Выйти
                <img src="@/assets/uploads/images/sidebar/logout.svg" alt="" />
              </a>
            </div>
          </transition>
        </div>
      </div>
      <router-link
        to="/"
        class="header__logo header__logo--desktop"
        @click="closeMenu"
      >
        <img
          src="@/assets/uploads/images/header/logo-blue.svg"
          alt="логотип к31"
        />
      </router-link>
      <div class="header__col header__col--right">
        <div v-if="!user" class="header__login">
          <div class="header__login-link" @click="closeMenu">
            <router-link to="/auth/phone" style="display: inline-block">
              вход/регистрация
            </router-link>
          </div>
        </div>
        <a :href="`tel:+${headerPhoneLink}`" class="header__tel">
          <IconInCircle bg-color="#E1E8F4" width="32" height="32">
            <img
              src="@/assets/uploads/images/header/phone.svg"
              alt="иконка телефона"
            />
          </IconInCircle>
          <span>{{ regexpPhone(headerPhoneLink) }}</span>
        </a>
        <NotificationBell />
      </div>
    </div>
  </header>
  <div class="announce" v-if="announceVisible">
    <div class="announce__container container">
      <div class="announce__wrapper">
        <div class="announce__top">
          <img
            src="@/assets/uploads/icon/common/alert.svg"
            alt="восклицательный знак"
          />
          <a
            v-if="announce.link"
            :href="announce.link"
            target="_blank"
            class="announce__link"
          >
            <span class="text">
              {{ announce.description }}
            </span>
          </a>

          <p v-else class="announce__text">
            {{ announce.description }}
          </p>
        </div>

        <button
          @click="announceVisible = false"
          class="announce__close"
          type="button"
        >
          <img
            src="@/assets/uploads/icon/common/close-alert.svg"
            alt="знак закрытия"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconInCircle from '@/components/ui/IconInCircle.vue'
import NotificationBell from '@/components/layout/header/NotificationBell.vue'
import { computed, inject, onMounted, reactive, ref, toRefs } from 'vue'
import { useMenuStore } from '@/stores/MenuStore'
import axios from 'axios'
import { regexpPhone } from '@/utilits/regexpPhone'
import { usePhoneLink } from '@/composables/usePhoneLink'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'

export default {
  name: 'TheHeader',
  methods: { regexpPhone },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    },
    headerPadding: {
      type: String,
      required: false,
      default: '40px'
    }
  },
  components: { NotificationBell, IconInCircle },

  setup (props) {
    const menuStore = useMenuStore()
    const { closeMenu } = menuStore
    const { user } = toRefs(props)
    const userStore = useUserStore()
    const { userPrimaryId } = storeToRefs(userStore)
    const { changeUserToFamilyMember } = userStore
    const toast = inject('toast')
    const announceVisible = ref(false)
    const isOpenUserList = ref(false)
    const announce = reactive({
      link: null,
      description: 'asddsadsasdasda asdasd adsdasasd asdasddsa'
    })
    const iconColors = reactive({
      man: '#D1F9EF',
      woman: '#FBCDCD',
      boy: '#F5FBCD',
      girl: '#F5E6FC',
      ufo: '#E4FCE6'
    })

    const { phone: headerPhoneLink } = usePhoneLink(
      'ios_header_phone',
      'android_header_phone',
      'header_phone',
      '74950214565'
    )
    const logOut = () => {
      userStore.logOut()
    }
    const openUserList = () => {
      isOpenUserList.value = !isOpenUserList.value
    }
    const hideUserList = () => {
      isOpenUserList.value = false
    }
    const showErrorToaster = () => {
      toast.error('Функционал в разработке', {
        position: 'top-right'
      })
    }
    const changeCurrentUser = (card) => {
      changeUserToFamilyMember(card)
      setTimeout(hideUserList, 100)
    }

    const userPhoto = computed(() => {
      if (!user.value) return

      return user.value.photo
    })

    const userFullName = computed(() => {
      if (user.value) {
        const middleName = user.value.middle_name
        const firstName = user.value.first_name
        const lastName = user.value.last_name

        return `
        ${lastName ?? ''}
        ${firstName ? firstName[0].toUpperCase() : ''}.
        ${middleName ? middleName[0].toUpperCase() : ''}.`
      }

      return ''
    })
    const userColor = (icon) => {
      return {
        backgroundColor: iconColors[icon] || ''
      }
    }

    const getAnnounce = async () => {
      try {
        const responce = await axios('/v1/announce')
        announceVisible.value = true

        if (responce.data.status === 'ok') {
          announce.link = responce.data.data[0].link
          announce.description = responce.data.data[0].description
        } else {
          announce.link = null
          announce.description = null
          announceVisible.value = false
        }
      } catch (e) {
        announceVisible.value = false
        announce.link = null
        announce.description = null
      }
    }

    onMounted(async () => {
      await getAnnounce()
    })

    return {
      userFullName,
      showErrorToaster,
      closeMenu,
      announce,
      announceVisible,
      headerPhoneLink,
      isOpenUserList,
      openUserList,
      changeCurrentUser,
      userColor,
      userPrimaryId,
      userPhoto,
      hideUserList,
      logOut
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  padding-bottom: 20px;
  padding-top: 20px;
  background: #082b53;
  @include desktop {
    background: #fff;
    padding: 20px 0;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }

  &__logo {
    @include desktop {
      display: none;
    }
  }

  &__person {
    margin-right: auto;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__icon {
    flex: 1 0 auto;
  }

  &__bottom {
    max-width: 345px;
    @include desktop {
      display: none;
    }
  }
}

.header__logo--desktop {
  display: none;
  @include desktop {
    display: block;
    img {
      width: 85px;
    }
  }
}

.header__tel {
  align-items: center;
  display: flex;

  img {
    filter: invert(9%) sepia(79%) saturate(2780%) hue-rotate(200deg)
      brightness(93%) contrast(102%);
  }

  span {
    display: none;
    color: $blue;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    margin-left: 12px;
    @include desktop {
      display: block;
    }
  }
}

.header__login {
  display: none;
  @include desktop {
    display: block;
  }
}

.header__login.header__login--mobile {
  display: block;
  @include desktop {
    display: none;
  }
}

.header__login a {
  border-radius: 30px;
  background: #bac7de;
  color: #002856;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  padding: 10px 11px;
  white-space: nowrap;
  transition: 0.2s;

  &:hover {
    background: #e1e8f4;
  }

  @include desktop {
    font-size: 14px;
    padding: 12px 20px;
  }
}

.header__col {
  display: flex;
  align-items: center;
  position: relative;

  &--left {
    gap: 25px;
    min-width: 250px;
  }

  &--right {
    gap: 18px;
    @include desktop {
      gap: 32px;
    }
  }
}

.announce {
  margin-top: 10px;
  margin-bottom: 10px;
}

.announce__container {
}

.announce__link {
  text-decoration: underline;
}

.announce__wrapper {
  border-radius: 8px;
  background: $orange;
  gap: 10px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
}

.announce__link {
}

.announce__top {
  display: flex;
  align-items: start;
  flex: 1;
  gap: 10px;
}

.announce__text {
  color: #062e4f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.announce__close {
  padding: 5px;
  align-self: start;
  border: unset;
}

.header__user-name {
  p {
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    @include desktop {
      color: #002856;
      font-size: 16px;
    }
  }

  span {
    font-size: 10px;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    @include desktop {
      color: #7f8da9;
      font-size: 13px;
    }
  }
}

.header__user-modal {
  position: absolute;
  top: 55px;
  width: 255px;
  margin: 0;
  padding: 12px;
  transform-origin: top;
  transition: transform 0.4s ease-in-out;
  z-index: 1000;
  border-radius: 8px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0px 5px 15px 0px #0000001a;
  @include desktop {
    width: 380px;
  }
}

.header__user-list {
  list-style-type: none;

  li {
    padding: 8px 0;
    border-bottom: 1px solid #e1e8f4;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

.slide-enter,
.slide-leave-to {
  transform: scaleY(0);
  transition: 0.2s;
}

.header__user-select {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  .header__user-arrow {
    filter: invert(96%) sepia(0%) saturate(7495%) hue-rotate(89deg)
      brightness(102%) contrast(106%);
    transform: rotate(-90deg);
    width: 6px;
    height: 9px;
    margin-left: 3px;
    transition: 0.2s;
    &.opened {
      transform: rotate(90deg);
    }
    @include desktop {
      filter: invert(13%) sepia(18%) saturate(7489%) hue-rotate(196deg)
        brightness(91%) contrast(104%);
    }
  }
}

.header__user-icon {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  overflow: hidden;
  @include desktop {
    height: 40px;
    width: 40px;
  }
}

.header__user-icon-profile {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  overflow: hidden;
  @include desktop {
    height: 40px;
    width: 40px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.header__user-list {
  li {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  .header__user-name {
    p {
      color: #002856;
    }

    span {
      color: #7f8da9;
    }
  }
}

.primary {
  > .header__user-icon {
    border: 1px solid #f1842c;
    padding: 1px;
  }
}
</style>
