import { getCookie } from '@/utilits/getCookie'
import FilesView from '@/views/FilesView.vue'
import FinanceView from '@/views/FinanceView.vue'
import FingerView from '@/views/FingerView.vue'
import MyAnalyzesView from '@/views/MyAnalyzesView.vue'
import MyScheduleView from '@/views/MyScheduleView.vue'
import PreparationView from '@/views/PreparationView.vue'
import AuthView from '@/views/auth/AuthView.vue'
import GosuslugiWithTokenView from '@/views/auth/GosuslugiWithTokenView.vue'
import LoginGosuslugiView from '@/views/auth/LoginGosuslugiView.vue'
import LoginMembersView from '@/views/auth/LoginMembersView.vue'
import LoginPhoneView from '@/views/auth/LoginPhoneView.vue'
import RegisterNewView from '@/views/auth/RegisterNewView.vue'
import RegisterOptionsView from '@/views/auth/RegisterOptionsView.vue'
import RegisterView from '@/views/auth/RegisterView.vue'
import AppointmentsCalendarView from '@/views/calendar/AppointmentsCalendarView.vue'
import CalendarView from '@/views/calendar/CalendarView.vue'
import PregnancyCalendarView from '@/views/calendar/pregnancy/PregnancyCalendarView.vue'
import PregnancySettingsView from '@/views/calendar/pregnancy/PregnancySettingsView.vue'
import VaccinationCalendarView from '@/views/calendar/VaccinationCalendarView.vue'
import GlucoseFirstTimeView from '@/views/calendar/glucose/GlucoseFirstTimeView.vue'
import GlucoseSettingsView from '@/views/calendar/glucose/GlucoseSettingsView.vue'
import GlucoseCalendarView from '@/views/calendar/glucose/GlucoseCalendarView.vue'
import GlucoseMenuView from '@/views/calendar/glucose/GlucoseMenuView.vue'
import GlucoseMeasurementFormView from '@/views/calendar/glucose/forms/GlucoseMeasurementFormView.vue'
import GlucoseMealFormView from '@/views/calendar/glucose/forms/GlucoseMealFormView.vue'
import GlucoseActivityFormView from '@/views/calendar/glucose/forms/GlucoseActivityFormView.vue'
import ActivityCalendarView from '@/views/calendar/activity/ActivityCalendarView.vue'
import ActivitySettingsView from '@/views/calendar/activity/ActivitySettingsView.vue'
import MedCardView from '@/views/med-card/MedCardView.vue'
import NotificationsView from '@/views/notifications/NotificationsView.vue'
import OffersView from '@/views/offers/OffersView.vue'
import OneOffersView from '@/views/offers/one-offers/OneOffersView.vue'
import RequestView from '@/views/support/RequestView.vue'
import ChatCallCenterView from '@/views/call-center/ChatCallCenterView.vue'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
// import PatientFormView from '@/views/PatientFormView.vue'
import TaxFormView from '@/views/TaxFormView.vue'
import ProgramsSelectionView from '@/views/ProgramsSelectionView.vue'
// import MedCardResearchView from '@/views/med-card/MedCardResearchView.vue'
// import MedCardHistoryView from '@/views/med-card/MedCardHistoryView.vue'
// import MedCardAnalyzesView from '@/views/med-card/MedCardAnalyzesView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Личный кабинет К+31'
    }
  },
  {
    path: '/login',
    name: 'login',
    redirect: '/auth/phone',
    component: LoginView,
    meta: {
      title: 'Вход - Личный кабинет К+31'
    }
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView,
    redirect: '/auth/phone',
    meta: {
      title: 'Вход - Личный кабинет К+31'
    },
    children: [
      {
        path: '/auth/phone',
        name: 'auth-phone',
        component: LoginPhoneView
      },
      {
        path: '/auth/members',
        name: 'auth-members',
        component: LoginMembersView
      },
      {
        path: '/auth/gosuslugi',
        name: 'auth-gosuslugi',
        component: LoginGosuslugiView
      },
      {
        path: '/auth/gosuslugi/:token',
        name: 'auth-gosuslugi-token',
        component: GosuslugiWithTokenView
      }
    ]
  },
  {
    path: '/pin-code',
    name: 'auth-pin-code',
    component: () => import('../views/pin-code/PinCodeView.vue'),
    meta: {
      title: 'Пин-код - Личный кабинет К+31'
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/profile/ProfileView.vue'),
    redirect: '/profile/details',
    meta: {
      title: 'Профиль - Личный кабинет К+31'
    },
    children: [
      {
        path: '/profile/details',
        name: 'profile-details',
        component: () =>
          import('../views/profile/children/ProfileDetailsView.vue')
      },
      {
        path: '/profile/car',
        name: 'profile-car',
        component: () => import('../views/profile/children/ProfileCarView.vue')
      },
      {
        path: '/profile/family',
        name: 'profile-family',
        component: () =>
          import('../views/profile/children/ProfileFamilyView.vue')
      },
      {
        path: '/profile/programs',
        name: 'profile-programs',
        component: () =>
          import('../views/profile/children/ProfileProgramsView.vue')
      }
    ]
  },
  {
    path: '/programs-selection',
    name: 'programs-selection',
    component: ProgramsSelectionView,
    meta: {
      title: 'Программы - Личный кабинет К+31'
    }
  },
  {
    path: '/profile/add-medcard',
    name: 'add-medcard',
    component: () =>
      import('../views/profile/add-medcard/ProfileAddMedCardView.vue'),
    meta: {
      title: 'Добавить новую карту - Личный кабинет К+31'
    }
  },
  {
    path: '/parking-pass/:id',
    name: 'parking-pass',
    component: () => import('../views/parking-pass/ParkingPassView.vue'),
    meta: {
      title: 'Заказ пропуска на парковку - Личный кабинет К+31'
    }
  },
  {
    path: '/favorites',
    component: () => import('../views/FavoritesView.vue'),
    name: 'favorites',
    meta: {
      title: 'Избранное - Личный кабинет К+31'
    },
    redirect: '/favorites/favorites-doctors',
    children: [
      {
        path: '/favorites/favorites-doctors',
        name: 'favorites-doctors',
        component: () => import('../views/FavoritesDoctorsView.vue')
      }
    ]
  },
  {
    path: '/calendar-menstruation',
    name: 'calendar-menstruation',
    component: () => import('../views/calendar/PeriodCalendarView.vue'),
    meta: {
      title: 'Менструальный цикл - Личный кабинет К+31'
    }
  },
  {
    path: '/parking-pass/add/:id',
    name: 'parking-pass-add',
    component: () => import('../views/parking-pass/ParkingPassAddView.vue'),
    meta: {
      title: 'Добавить данные о своем авто - Личный кабинет К+31'
    }
  },
  {
    path: '/patient-member',
    name: 'patient-member',
    component: () => import('../views/patient-form/PatientMember.vue'),
    meta: {
      title: 'Анкета пациента - Личный кабинет К+31'
    }
  },
  {
    path: '/patient-form',
    name: 'patient-form',
    component: () => import('../views/patient-form/PatientFormView.vue'),
    meta: {
      title: 'Анкета пациента - Личный кабинет К+31'
    }
  },
  {
    path: '/patient-form-gosuslugi',
    name: 'patient-form-gosuslugi',
    component: () =>
      import('../views/patient-form/PatientFormGosuslugiView.vue'),
    meta: {
      title: 'Анкета пациента - Личный кабинет К+31'
    }
  },
  {
    path: '/patient-gosuslugi',
    name: 'patient-gosuslugi',
    component: () => import('../views/patient-form/PatientGosuslugiView.vue'),
    meta: {
      title: 'Анкета пациента - Личный кабинет К+31'
    }
  },
  {
    path: '/patient-gosuslugi/:etag',
    name: 'patient-gosuslugi-etag',
    component: () => import('../views/patient-form/PatientFormEtagView.vue'),
    meta: {
      title: 'Анкета пациента - Личный кабинет К+31'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/register',
    component: RegisterView,
    redirect: '/register/options',
    meta: {
      title: 'Регистрация - Личный кабинет К+31'
    },
    children: [
      {
        name: 'register-options',
        path: 'options',
        component: RegisterOptionsView
      },
      {
        name: 'register-new',
        path: 'new',
        component: RegisterNewView
      }
    ]
  },
  {
    path: '/med-card',
    component: MedCardView,
    name: 'med-card',
    meta: {
      title: 'Медицинская карта - Личный кабинет К+31'
    }
  },
  {
    path: '/appiontment-review/:score/:doc_id/:id',
    name: 'appointment-review',
    props: true,
    component: () =>
      import('../views/med-card/AppointmentReviewView.vue')
  },
  {
    path: '/notifications',
    component: NotificationsView,
    name: 'notifications',
    meta: {
      title: 'Уведомления - Личный кабинет К+31'
    }
  },
  {
    path: '/my-analyzes/:id/:resourceId',
    name: 'my-analyzes',
    component: MyAnalyzesView,
    meta: {
      title: 'Анализы - Личный кабинет К+31'
    }
  },
  {
    path: '/my-schedule/:id/:resourceId/:exId',
    name: 'my-schedule',
    component: MyScheduleView,
    meta: {
      title: 'Личный кабинет К+31'
    }
  },
  {
    path: '/chats',
    name: 'chats',
    component: () => import('../views/chat/ChatsView.vue')
  },
  {
    path: '/chats/:id/:doctorId',
    name: 'chat-room',
    component: () => import('../views/chat/ChatRoomView.vue')
  },
  {
    path: '/programs',
    component: () => import('../views/programs/ProgramsView.vue'),
    meta: {
      title: 'Программы - Личный кабинет К+31'
    },
    name: 'program',
    redirect: '/program/all',
    children: [
      {
        path: '/program/my',
        name: 'program-my',
        component: () => import('../views/programs/MyProgramsView.vue')
      },
      {
        path: '/program/all',
        name: 'program-all',
        component: () => import('../views/programs/AllProgramsView.vue')
      }
    ]
  },

  {
    path: '/doctors',
    component: () => import('../views/doctors/DoctorsView.vue'),
    name: 'doctors',
    meta: {
      title: 'Врачи - Личный кабинет К+31'
    },
    redirect: '/doctors/all-doctors',
    children: [
      {
        path: '/doctors/all-doctors',
        name: 'all-doctors',
        component: () => import('../views/doctors/AllDoctorsView.vue')
      },
      {
        path: '/doctors/departments',
        name: 'doctors-departments',
        component: () => import('../views/doctors/DoctorsDepartmentsView.vue')
      },
      {
        path: '/doctors/departments/:department',
        name: 'selected-department',
        component: () => import('../views/doctors/SelectedDepartmentView.vue')
      },
      {
        path: '/doctors/:doctor',
        name: 'selected-doctor',
        component: () => import('../views/doctors/SelectedDoctorView.vue')
      },
      {
        path: '/doctors/telemed/:doctor',
        name: 'selected-telemed-doctor',
        component: () =>
          import('../views/doctors/SelectedDoctorTelemedView.vue')
      }
    ]
  },

  {
    path: '/appointment-approve/:id',
    name: 'selected-appointment',
    component: () => import('../views/appointment-approve/UpcomingAppointmentView.vue')
  },

  {
    path: '/appointment-approve/:id/reschedule',
    name: 'reschedule-appointment',
    component: () => import('../views/appointment-approve/RescheduleAppointmentView.vue')
  },

  {
    path: '/appointment-approve/:id/success',
    name: 'reschedule-success',
    component: () => import('../views/appointment-approve/RescheduleSuccessView.vue')
  },

  {
    path: '/appointment-approve/:id/cancel',
    name: 'reschedule-cancel',
    component: () => import('../views/appointment-approve/RescheduleCancelView.vue')
  },

  {
    path: '/online-appointment',
    name: 'online-appointment',
    component: () =>
      import('../views/online-appointment/OnlineAppointmentView.vue'),
    meta: {
      title: 'Онлайн-запись - Личный кабинет К+31'
    }
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('../views/support/SupportView.vue'),
    meta: {
      title: 'Техническая поддержка - Личный кабинет К+31'
    },
    redirect: '/support/form',
    children: [
      {
        path: '/support/form',
        name: 'support-form',
        component: () =>
          import('../views/support/children/SupportFormView.vue')
      },
      {
        path: '/support/requests',
        name: 'support-requests',
        component: () =>
          import('../views/support/children/SupportRequestsView.vue')
      }
    ]
  },
  {
    path: '/support/requests/:id',
    name: 'support-r',
    component: RequestView,
    meta: {
      title: 'Техподдержка - Личный кабинет К+31'
    }
  },
  {
    path: '/call-center-chat',
    name: 'call-center-chat',
    component: ChatCallCenterView,
    meta: {
      title: 'Чат с колл-центром - Личный кабинет К+31'
    }
  },

  {
    path: '/parking',
    name: 'parking',
    component: () => import('../views/ParkingView.vue'),
    meta: {
      title: 'Паркинг - Личный кабинет К+31'
    }
  },
  {
    path: '/online-consultation',
    name: 'online-consultation',
    component: () =>
      import('../views/online-consultation/OnlineConsultationView.vue'),
    meta: {
      title: 'Телемедицина - Личный кабинет К+31'
    }
  },
  {
    path: '/telemed-gosuslugi',
    name: 'telemed-gosuslugi',
    component: () =>
      import('../views/telemed-gosuslugi/TelemedGosuslugiView.vue'),
    meta: {
      title: 'Телемедицина - Личный кабинет К+31'
    }
  },
  {
    path: '/telemed-gosuslugi/:etag',
    name: 'telemed-gosuslugi-etag',
    component: () => import('../views/telemed-gosuslugi/TelemedEtagView.vue'),
    meta: {
      title: 'Телемедицина - Личный кабинет К+31'
    }
  },
  {
    path: '/telemed-payment/:result',
    name: 'telemed-payment',
    component: () => import('../views/telemed-payment/TelemedPaymentView.vue'),
    meta: {
      title: 'Телемедицина - Личный кабинет К+31'
    }
  },
  {
    path: '/agreement-appointment',
    name: 'agreement-appointment',
    component: () =>
      import('../views/online-consultation/AgreementAppointment.vue'),
    meta: {
      title: 'Телемедицина - Личный кабинет К+31'
    }
  },
  {
    path: '/finance',
    name: 'finance',
    component: FinanceView,
    meta: {
      title: 'Финансы - Личный кабинет К+31'
    }
  },
  {
    path: '/finance/:result',
    name: 'finance-result',
    component: () => import('../views/finance-result/FinanceResultView.vue'),
    meta: {
      title: 'Финансы - Личный кабинет К+31'
    }
  },
  {
    path: '/call-doctor',
    name: 'call-doctor',
    component: () => import('../views/call-doctor/CallDoctorView.vue'),
    meta: {
      title: 'Вызов врача на дом - Личный кабинет К+31'
    }
  },
  {
    path: '/preparation',
    name: 'preparation',
    component: PreparationView,
    meta: {
      title: 'Подготовка к исследованиям - Личный кабинет К+31'
    }
  },
  {
    path: '/files',
    name: 'files',
    component: FilesView,
    meta: {
      title: 'Мои файлы - Личный кабинет К+31'
    }
  },
  {
    path: '/offers',
    name: 'offers',
    component: OffersView,
    meta: {
      title: 'Акции - Личный кабинет К+31'
    }
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('../views/services/ServicesView.vue'),
    meta: {
      title: 'Услуги - Личный кабинет К+31'
    },
    redirect: '/services/search-departments',
    children: [
      {
        path: '/services/search-alphabet',
        name: 'services-search-alphabet',
        component: () =>
          import('../views/services/children/ServicesSearchAlphabetView.vue')
      },
      {
        path: '/services/search-departments',
        name: 'services-search-departments',
        component: () =>
          import('../views/services/children/ServicesSearchDepartmentView.vue')
      }
    ]
  },
  {
    path: '/services/search-departments/:id',
    name: 'services-search-target-departments',
    component: () =>
      import('../views/services/ServicesSearchTargetDepartmentView.vue'),
    meta: {
      title: 'Услуги - Личный кабинет К+31'
    }
  },
  {
    path: '/services/:id/:type',
    name: 'one-service',
    component: () => import('../views/services/OneServiceView.vue'),
    meta: {
      title: 'Услуги - Личный кабинет К+31'
    }
  },
  {
    path: '/offers/:alias',
    name: 'one-offers',
    component: OneOffersView,
    meta: {
      title: 'Акция - Личный кабинет К+31'
    }
  },
  {
    path: '/index.html',
    name: 'finger',
    component: FingerView,
    meta: {
      title: 'Вход - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: CalendarView,
    meta: {
      title: 'Календари - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-appointments',
    name: 'calendar-appointments',
    component: AppointmentsCalendarView,
    meta: {
      title: 'Мои записи - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-medications',
    name: 'calendar-medications',
    component: () => import('../views/calendar/MedicationsCalendarView.vue'),
    meta: {
      title: 'Мои записи - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-medications-form',
    name: 'calendar-medications-form',
    component: () =>
      import('../views/calendar/MedicationsCalendarFormView.vue'),
    meta: {
      title: 'Мои записи - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-medications-form-change/:pill',
    name: 'calendar-medications-form-change',
    component: () =>
      import('../views/calendar/MedicationsCalendarFormChangeView.vue'),
    meta: {
      title: 'Мои записи - Личный кабинет К+31'
    }
  },

  {
    path: '/calendar-pregnancy',
    name: 'calendar-pregnancy',
    component: PregnancyCalendarView,
    meta: {
      title: 'Беременность - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-pregnancy-settings',
    name: 'calendar-pregnancy-settings',
    component: PregnancySettingsView,
    meta: {
      title: 'Беременность - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-glucose-welcome',
    name: 'calendar-glucose-welcome',
    component: GlucoseFirstTimeView,
    meta: {
      title: 'Измерение глюкозы - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-glucose',
    name: 'calendar-glucose',
    component: GlucoseCalendarView,
    meta: {
      title: 'Измерение глюкозы - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-glucose-settings',
    name: 'calendar-glucose-settings',
    component: GlucoseSettingsView,
    meta: {
      title: 'Измерение глюкозы - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-glucose-menu',
    name: 'calendar-glucose-menu',
    component: GlucoseMenuView,
    meta: {
      title: 'Измерение глюкозы - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-glucose-measurment',
    name: 'calendar-glucose-measurment',
    component: GlucoseMeasurementFormView,
    meta: {
      title: 'Измерение глюкозы - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-glucose-meal',
    name: 'calendar-glucose-meal',
    component: GlucoseMealFormView,
    meta: {
      title: 'Измерение глюкозы - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-glucose-activity',
    name: 'calendar-glucose-activity',
    component: GlucoseActivityFormView,
    meta: {
      title: 'Измерение глюкозы - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-glucose-form',
    name: 'calendar-glucose-form',
    component: () =>
      import('../views/calendar/glucose/GlucoseFormView.vue'),
    meta: {
      title: 'Измерение глюкозы - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-pressure',
    name: 'calendar-pressure',
    component: () =>
      import('../views/calendar/pressure/PressureCalendarView.vue'),
    meta: {
      title: 'Измерение давления - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-pressure-form',
    name: 'calendar-pressure-form',
    component: () => import('../views/calendar/pressure/PressureFormView.vue'),
    meta: {
      title: 'Измерение давления - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-pressure-chart-form',
    name: 'calendar-pressure-chart-form',
    component: () =>
      import('../views/calendar/pressure/PressureFormChartView.vue'),
    meta: {
      title: 'Измерение давления - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-vaccination',
    name: 'calendar-vaccination',
    component: VaccinationCalendarView,
    meta: {
      title: 'Вакцинация - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-national',
    name: 'calendar-national',
    component: () => import('../views/calendar/NationalCalendarView.vue'),
    meta: {
      title: 'Вакцинация - Личный кабинет К+31'
    }
  },
  {
    path: '/vaccination-add',
    name: 'vaccination-add',
    component: () => import('../views/calendar/VaccinationAddView.vue'),
    meta: {
      title: 'Вакцинация - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-activity',
    name: 'calendar-activity',
    component: ActivityCalendarView,
    meta: {
      title: 'Моя активность - Личный кабинет К+31'
    }
  },
  {
    path: '/calendar-activity-settings',
    name: 'calendar-activity-settings',
    component: ActivitySettingsView,
    meta: {
      title: 'Моя активность - Личный кабинет К+31'
    }
  },
  {
    path: '/tax-form',
    name: 'tax-form',
    component: TaxFormView,
    meta: {
      title: 'Налоговый вычет - Личный кабинет К+31'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error',
    component: () => import('../views/ErrorView.vue'),
    meta: {
      title: 'Личный кабинет К+31'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    // Если есть hash, прокручиваем к элементу по нему
    if (to.hash) {
      return { el: to.hash }
    }

    // Если есть savedPosition, возвращаем его же (переход назад/вперёд)
    if (savedPosition) {
      return savedPosition
    }

    // Если оба маршрута в мета свойствах имеют saveScrollPosition - не меняем положение
    if (to.meta.saveScrollPosition && from.meta.saveScrollPosition) {
      return false
    }

    // По умолчанию возвращаемся в начало
    return { left: 0, top: 0 }
  },

  routes
})

router.beforeEach(async (to, from) => {
  const token = getCookie('user_token')
  const protectedRoutes = [
    'online-appointment',
    'med-card',
    'finance',
    'files',
    'profile',
    'profile-details',
    'profile-family',
    'feedback',
    'doctors-departments',
    'selected-department',
    'call-doctor',
    'calendar',
    'agreement-appointment',
    'online-consultation',
    'notifications',
    'support-r',
    'support-form',
    'support-requests',
    'parking',
    'tax-form',
    'patient-member',
    'patient-form',
    'patient-form-gosuslugi',
    'patient-gosuslugi',
    'patient-gosuslugi-etag',
    'telemed-gosuslugi',
    'telemed-gosuslugi-etag',
    'telemed-payment',
    'finance-result',
    'calendar-vaccination',
    'calendar-national',
    'calendar-medications',
    'calendar-medications-form',
    'calendar-medications-form-change',
    'calendar-glucose',
    'calendar-glucose-chart-form',
    'calendar-glucose-form',
    'vaccination-add',
    'parking-pass',
    'parking-pass-add',
    'profile-car',
    'calendar-menstruation',
    'selected-telemed-doctor',
    'calendar-pressure',
    'calendar-pressure-form',
    'calendar-pressure-chart-form'
  ]

  const authPath = [
    'auth',
    'auth-phone',
    'auth-members',
    'auth-gosuslugi',
    'register-options',
    'register-new'
  ]

  if (token && authPath.includes(to.name)) {
    return { name: 'home' }
  }

  if (!token && protectedRoutes.includes(to.name)) {
    return { name: 'auth' }
  }

  if (to.name === 'finger' && from.name === 'home') {
    return false
  }
  const title = to.meta.title
  if (title) {
    document.title = title
  }
})

export default router
