export const deleteBiometricSecret = () => {
  window?.Fingerprint?.registerBiometricSecret(
    {
      description: 'Выход из аккаунта',
      secret: '',
      invalidateOnEnrollment: true,
      disableBackup: true // Always disabled on Android,
    },
    successCallback,
    errorCallback
  )

  function successCallback () {
    // alert('Authentication successful')
  }

  function errorCallback () {
    // alert('Authentication invalid ' + error.message)
  }
}
