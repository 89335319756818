<template>
  <div class="stories-wrapper">
    <div @click.prevent="closeStories" class="close"></div>
    <div class="stories">

      <div v-for="(story, index) in stories" :key="index" class="story" :style="getStoryStyle(index)"
        @click="index !== indexSelected ? selectSlide(index) : ''">

        <div class="story__source" @click="isPaused ? playStory($event) : pauseStory($event)">

          <video playsinline poster="@/assets/uploads/images/calendar/pregnancy/transparent-pic.png" :id="getSrc(story, index).url + index" v-if="getSrc(story, index).type === 'video'"
            :src="getSrc(story, index).url" class="story__video" autoplay muted></video>

          <!-- <YouTubePlayer v-if="getSrc(story, index).type === 'video'" :src="getSrc(story, index).url" :youtubePlayerIsPlaying="youtubePlayerIsPlaying" @ready="handleYouTubeReady" :indexSelected="indexSelected" :currentSlide="key" :stories="stories"/> -->

          <img v-else-if="getSrc(story, index).url" :src="getSrc(story, index).url" alt="" />

          <div class="story__header" v-if="index === indexSelected">
            <div class="time">
              <div class="time__item" v-for="(elm, timeIndex) in story.images.length" :key="timeIndex">
                <div class="time__fill" :style="getTimeFillStyle(timeIndex)"></div>
              </div>
            </div>
            <div class="story__top">
              <div class="user">
                <div class="user__image">
                  <img :src="story.picture" alt="" />
                </div>
                <div class="user__name">
                  {{ story.username }}
                </div>
              </div>
              <div class="story__time">{{ story.time }}</div>
            </div>
          </div>

          <div class="story__body"
            :class="{ 'article': story.storiesItems.type === 'Статья', 'story__body__video': getSrc(story, index).type === 'video' }">
            <div class="user" v-if="index !== indexSelected">
              <div class="user__image" :style="getNotViewedIndex(story) === -1 ? `background: #FFFFFF` : ''
                ">
                <img :src="story.picture" alt="" />
              </div>
              <div class="user__name">
                {{ story.username }}
              </div>
            </div>
            <slot v-if="showInnerContent && index === indexSelected" name="innerContent" :story="story"></slot>
          </div>

        </div>

        <div v-if="index === indexSelected" class="story__icon story__icon--prev" @click="prev(index)">
        </div>

        <div v-if="index === indexSelected" class="story__icon story__icon--next" @click="next(index)">
        </div>

        <slot v-if="showOuterContent && index === indexSelected" name="outerContent" :story="story"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount, nextTick, onActivated, onDeactivated } from 'vue'
// import YouTubePlayer from '@/components/Common/YouTubePlayer.vue'

export default {
  components: {
    // YouTubePlayer
  },
  name: 'TheStories',
  emits: ['closeStories'],
  props: {
    stories: {
      type: Array,
      required: true
    },
    duration: {
      type: Number,
      default: 3000
    },
    currentIndex: {
      type: Number,
      default: 0
    },
    showInnerContent: {
      type: Boolean,
      default: false
    },
    showOuterContent: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const isClosed = ref(false)
    const indexSelected = ref(0)
    const difference = ref(0)
    const currentVideoDuration = ref(0)
    const key = ref(0)
    const percent = ref(0)
    const timer = ref(0)
    const progress = ref(0)
    const interval = ref(0)
    const isPaused = ref(false)
    const remainingDuration = ref(0)
    const progressBeforePause = ref(0)
    // const youtubePlayerIsPlaying = ref(0)
    // const isYoutubePlayerReady = ref(false)

    // const IS_YOUTUBE = 0

    const isAllStoriesEnd = computed(() => {
      return (
        indexSelected.value >= props.stories.length - 1 && isCurrentAllImagesEnd.value
      )
    })

    const isCurrentAllImagesEnd = computed(() => {
      return key.value >= props.stories[indexSelected.value].images.length - 1
    })

    const getStoryStyle = (index) => {
      return index === indexSelected.value
        ? 'transform: translate(0px)'
        : `transform: translate(${calculateTransform(
          index
        )}px) scale(0.3);cursor:pointer;`
    }

    const getTimeFillStyle = (timeIndex) => {
      return timeIndex === key.value
        ? `width: ${percent.value}%`
        : key.value > timeIndex
          ? 'width:100%'
          : 'width:0%'
    }

    const getSrc = (story, index) => {
      const viewedIndex = getLastViewedIndex(story)
      return index === indexSelected.value
        ? {
            url: story.images[key.value].url,
            type: story.images[key.value].type
          }
        : {
            url: story.images[viewedIndex].url,
            type: story.images[viewedIndex].type
          }
    }

    const getNotViewedIndex = (story) => {
      return story?.images.findIndex((item) => !item.viewed)
    }

    const getLastViewedIndex = (story) => {
      const keyIndex = getNotViewedIndex(story)
      const index = story.images.length - 1
      return keyIndex === -1 ? index : keyIndex
    }

    const selectSlide = (index) => {
      // console.log('select slide!!!!!')
      // isPaused.value = false
      // difference.value += indexSelected.value - index
      // stopVideo(props.stories[indexSelected.value].images[key.value].url)
      // indexSelected.value = index
      // key.value = getLastViewedIndex(props.stories[indexSelected.value])
      // resetTimeBar()
    }

    const onAllStoriesEnd = () => {
      if (props.stories[indexSelected.value].images[key.value].type === 'video') {
        const video = document.getElementById(props.stories[indexSelected.value].images[key.value].url + indexSelected.value)

        video.pause()
      }

      difference.value = 0
      indexSelected.value = 0
      key.value = 0
      emit('allStoriesEnd')
    }

    const onCurrentAllImagesEnd = (index) => {
      difference.value += index - (index + 1)
      stopVideo(props.stories[indexSelected.value].images[key.value].url)
      // eslint-disable-next-line
      props.stories[index].images[key.value].viewed = true
      indexSelected.value++
      key.value = 0
    }

    const onCurrentImageEnd = (index) => {
      if (key.value in props.stories[index].images) {
        // eslint-disable-next-line
        props.stories[index].images[key.value].viewed = true
        emit('сurrentImageEnd', key.value)
        key.value++
      } else {
        key.value = 0
      }
    }

    const next = () => {
      isPaused.value = false
      if (isAllStoriesEnd.value) {
        onAllStoriesEnd()
      } else if (isCurrentAllImagesEnd.value) {
        onCurrentAllImagesEnd(indexSelected.value)
      } else {
        // eslint-disable-next-line
        props.stories[indexSelected.value].images[key.value].viewed = true
        key.value++
      }

      resetTimeBar()
    }

    const prev = (index) => {
      if (props.stories[index].images[key.value].type === 'video') {
        const video = document.getElementById(props.stories[index].images[key.value].url + index)

        video.pause()
      }

      isPaused.value = false
      if (indexSelected.value <= 0 && key.value <= 0) {
        key.value = 0
      } else if (key.value <= 0) {
        difference.value += index - (index - 1)
        indexSelected.value--
        key.value = getLastViewedIndex(props.stories[indexSelected.value])
      } else {
        key.value--
        // eslint-disable-next-line
        props.stories[indexSelected.value].images[key.value].viewed = false
      }
      resetTimeBar()
    }

    const autoPlay = () => {
      if (isAllStoriesEnd.value) {
        onAllStoriesEnd()
      } else if (isCurrentAllImagesEnd.value) {
        onCurrentAllImagesEnd(indexSelected.value)
      } else {
        onCurrentImageEnd(indexSelected.value)
      }
      resetTimeBar()
    }

    const play = (customDuration) => {
      timer.value = new Date().getTime()
      progress.value = setInterval(() => {
        const time = new Date().getTime()
        const currentDuration = customDuration || props.duration
        if (remainingDuration.value > 0) {
          percent.value =
            progressBeforePause.value +
            Math.floor((100 * (time - timer.value)) / currentDuration)
        } else {
          percent.value = Math.floor(
            (100 * (time - timer.value)) / currentDuration
          )
        }
      }, (customDuration || props.duration) / 100)

      if (remainingDuration.value > 0) {
        interval.value = setInterval(autoPlay, remainingDuration.value)
      } else {
        interval.value = setInterval(autoPlay, customDuration || props.duration)
      }
    }

    const resetTimeBar = async () => {
      percent.value = 0
      clearInterval(interval.value)
      clearInterval(progress.value)
      remainingDuration.value = 0
      if (props.stories[indexSelected.value].images[key.value].type === 'video') {
        await nextTick()
        const video = document.getElementById(props.stories[indexSelected.value].images[key.value].url + indexSelected.value)
        let duration = video.duration * 1000
        currentVideoDuration.value = duration
        if (duration) {
          video.currentTime = 0
          video.play()
          video.muted = false
          play(duration)
        } else {
          video.addEventListener('loadeddata', () => {
            if (!isClosed.value) {
              duration = video.duration * 1000
              currentVideoDuration.value = duration
              video.currentTime = 0
              video.play()
              video.muted = false
              play(duration)
            } else {
              video.pause()
            }
          })
        }
      } else {
        play()
      }
    }

    const pauseStory = (event) => {
      if (event) {
        toggleVideo('pause', event)
        isPaused.value = true
        progressBeforePause.value = percent.value
        clearInterval(progress.value)
        clearInterval(interval.value)
        remainingDuration.value = currentVideoDuration.value - (progressBeforePause.value * currentVideoDuration.value) / 100
      } else {
        isPaused.value = true
        progressBeforePause.value = percent.value
        clearInterval(progress.value)
        clearInterval(interval.value)
        remainingDuration.value = props.duration - (progressBeforePause.value * props.duration) / 100
      }
    }

    const playStory = (event) => {
      if (event) {
        toggleVideo('play', event)
        isPaused.value = false
        play(currentVideoDuration.value)
      } else {
        isPaused.value = false
        play()
      }
    }

    const toggleVideo = (type, event) => {
      const video = document.getElementById(event.target?.id)
      if (video) {
        video[type]()
      }
      // if (IS_YOUTUBE) {
      //   type === 'play' ? youtubePlayerIsPlaying.value = 1 : youtubePlayerIsPlaying.value = 1
      // } else if (video) {
      //   video[type]()
      // }
    }

    const stopVideo = (id) => {
      const video = document.getElementById(id + indexSelected.value)

      if (video) {
        video.pause()
        video.currentTime = 0
      }

      // if (IS_YOUTUBE) {
      //   youtubePlayerIsPlaying.value = 0
      // } else if (video) {
      //   console.log('video', video)
      //   video.pause()
      //   video.currentTime = 0
      // }
    }

    const calculateTransform = (index) => {
      if (
        indexSelected.value - index === -1 ||
        indexSelected.value - index === 1
      ) {
        return 315 * (index + difference.value)
      }
      if (index > indexSelected.value) {
        return (315 + 315 * (index + difference.value)) * 0.5
      } else {
        return Math.abs((315 - 315 * (index + difference.value)) * 0.5) * -1
      }
    }

    const closeStories = () => {
      isClosed.value = true
      emit('closeStories')
    }

    // const handleYouTubeReady = (duration) => {
    //   console.log('YOU TUBE READY')
    //   currentVideoDuration.value = duration * 1000
    //   isYoutubePlayerReady.value = true
    //   play(currentVideoDuration.value)
    // }

    onActivated(() => {
      isClosed.value = false
    })

    onDeactivated(() => {
      document.removeEventListener('backbutton', closeStories)
    })

    onMounted(() => {
      document.addEventListener('backbutton', closeStories)
      isClosed.value = false
      indexSelected.value = props.currentIndex

      resetTimeBar()
    })

    onBeforeUnmount(() => {
      clearInterval(interval.value)
      clearInterval(progress.value)
      remainingDuration.value = 0
      percent.value = 0
    })

    return {
      indexSelected,
      key,
      percent,
      isPaused,
      getStoryStyle,
      getTimeFillStyle,
      getSrc,
      getNotViewedIndex,
      selectSlide,
      next,
      prev,
      pauseStory,
      playStory,
      calculateTransform,
      closeStories,
      autoPlay
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.stories-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(16, 16, 16, 0.98);
  font-family: sans-serif;
}

.stories {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.story {
  position: absolute;
  transition: transform 0.2s cubic-bezier(0.4, 0, 1, 1);

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__source {
    position: relative;
    background: #E1E8F4;
    width: 414px;
    height: 751px;
    background-size: contain;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: auto;
      flex: 1 1 auto;
    }

    img,
    video,
    iframe {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 8px 11px;
  }

  &__time {
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }

  &__top {
    display: flex;
    align-items: center;
  }

  &__body {
    position: absolute;
    left: 0;
    right: 0;

    .user {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .user__name {
      display: none;
    }

    .user__image {
      width: 140px;
      height: 140px;
      background: linear-gradient(180deg, #4c7cf6 0%, #6200c3 100%);
      margin: 0;
      padding: 5px;

      img {
        border: 5px solid #ffffff;
        border-radius: 100%;
      }
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    @media screen and (max-width: 768px) {
      top: 0;
      bottom: 0;
      transform: none;
      width: 100px;
      height: auto;
      background: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: 8px;
      left: 9px;
      border: solid #323232;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(135deg);
      border-radius: 1px;

      @media screen and (max-width: 768px) {
        content: none;
      }
    }

    &--prev {
      left: -35px;

      @media screen and (max-width: 768px) {
        left: 0;
      }
    }

    &--next {
      right: -35px;
      transform: translateY(-50%) rotate(180deg);

      @media screen and (max-width: 768px) {
        right: 0;
        transform: none;
      }
    }
  }
}

.user {
  display: flex;
  align-items: center;

  &__image {
    width: 32px;
    height: 32px;
    border-radius: 100px;
    overflow: hidden;
    margin-right: 8px;

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  &__name {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #ffffff;
    margin-right: 5px;
  }
}

.time {
  display: flex;
  margin-bottom: 10px;

  &__item {
    position: relative;
    width: 100%;
    height: 2px;
    margin-right: 4px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__fill {
    position: absolute;
    width: 100%;
    background: #ffffff;
    height: 2px;
  }
}

.close {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:after {
    content: '\00d7';
    color: #082b53;
    font-weight: 100;
    font-size: 35px;
    text-shadow: 0px 0px 2px #FFF;

    //@media (max-width: 600px) {
    //  color: $blue;
    //}
  }
}

.stories-wrapper {
  z-index: 10000;
}

.close {
  z-index: 10001;
}

.story__top {
  display: none !important;
}

.time__item {
  background: #bac7de !important;
}

.time__fill {
  background: #002856 !important;
}

.user__image {
  display: none !important;
}

.story__link:hover {
  text-decoration: none;
  color: #062e4f;
  background: #e1e8f4;
}

//.stories {
//  align-items: flex-start !important;
//}
//
//.stories-wrapper .close {
//  z-index: 10000;
//  top: 10px !important;
//  right: 10px !important;
//}
//
//.story__body {
//  bottom: 70px !important;
//}
//

//@media (max-width: 767px) {
//  .story__source {
//    background: #fff;
//  }
//
//  .stories {
//    max-height: 90%;
//  }
//}</style>

<style lang="scss">
.story__body__video {
  bottom: 0 !important;

  .story__content {
    .story__description {
      padding-bottom: 15px !important;
      .story__type {
        // display: none
      }

      .story__text {
        display: none
      }

      .story__link {
        // display: none
      }

      .story__title {
        // font-size: 24px;
        // font-weight: 700;
        // line-height: 28.8px;
        // text-align: left;

        color: #fff;
      }
    }
  }
}

.story__description {
  padding: 20px 15px 40px;
}

.story__type {
  background: #f1842c;
  border-radius: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #ffffff;
  padding: 4px 8px;
  margin-bottom: 10px;
}

.story__title {
  font-weight: 700;
  font-size: 20px;
  color: #002856;
  margin-bottom: 10px;
}

.story__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.01em;
  color: #002856;
}

.story__link {
  font-weight: 400;
  font-size: 15px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff !important;
  background: #002856;
  border-radius: 100px;
  padding: 10px;
  width: 100%;
  transition: 0.2s;
  margin-top: 20px;
  cursor: pointer;
}

.story__body {
  position: absolute;
  bottom: 70px;
  left: 0;
  right: 0;
}

.article {
  top: 50px;
  overflow: scroll;
  padding: 0 16px 50px 16px;
  bottom: 0 !important;
}

.story__video-iframe {
  width: 100%;
  height: 100%;
}

.story__video {
  background-image: url('@/assets/uploads/images/header/logo-blue.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 60%;
}
</style>
